#eventsVideoModal {
	.modal-dialog {
		max-width: 600px;
	}

	button {
		height: auto;
	}

	.modal-body {
		text-align: center;

		.youtube-video {
			width: 100%;
		}
	}
}