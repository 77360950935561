@import "../general/import-general-only";

$generalInfoPage-background: $white !default;
$generalInfoPage-shadow-color: $gray-secondary !default;
$generalInfoPage-headline-color: $primary-blue !default;
$generalInfoPage-a-color: $tertiary-blue !default;
$generalInfoPage-a-hover-color: $tertiary-blue-hover !default;

$fiftyPercentPaymentOption-color: $text-blue !default;
$fiftyPercentPaymentOption-content-background: $gray-light !default;
$fiftyPercentPaymentOption-content-gradient-background: radial-gradient(ellipse at center,  rgba(129,197,249,1) 0%,rgba(128,235,177,0.5) 50%,rgba(250,250,250,0.2) 70%,rgba(0,0,0,0) 100%) !default;
$fiftyPercentPaymentOption-header-color: $text-blue !default;
$fiftyPercentPaymentOption-header-highLighted-color: $secondary-green !default;
$fiftyPercentPaymentOption-button-background: $tertiary-blue !default;
$fiftyPercentPaymentOption-button-hover-background: $primary-blue !default;
$fiftyPercentPaymentOption-button-color: $white !default;
$fiftyPercentPaymentOption-benefitHolder-background: $secondary-green-light !default;

.content {
	padding: 20px;
	margin-bottom: 10px;
	word-break: break-word;
	background: $generalInfoPage-background;
	border-radius: 3px;
	box-shadow: 0 0 3px $generalInfoPage-shadow-color;

	h1, h2, h3, h4, h5, h6 .textArticleContainer h1, .textArticleContainer h2 {
		font-size: 32px;
		color: $generalInfoPage-headline-color;

		@include sf_pro_textsemibold;
	}

	h2, .textArticleContainer h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 18px;
	}

	h4, h5, h6 {
		font-size: 15px;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	a {
		color: $generalInfoPage-a-color;
		text-decoration: underline;

		&:hover {
			color: $generalInfoPage-a-hover-color;
			text-decoration: none;
		}
	}

	.contentText {
		font-size: 16px;
	}

	.fiftyPercentPaymentOptionContainer {
		h1 {
			font-size: 1.5em;
		}

		.benefitsBlock {
			margin-top: 24px;

			@include media-breakpoint-up(md) {
				margin: 50px 0;
			}
		}

		.benefitHolder {
			align-items: center;

			.tick {
				position: relative;
				width: 24px;
				height: 24px;
				background-color: $fiftyPercentPaymentOption-benefitHolder-background;

				&:after {
					position: absolute;
					top: -7px;
					left: 12px;
					display: block;
					width: 10px;
					height: 25px;
					content: "";
					border: solid $black;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}

			.benefit {
				width: 70%;
				margin-left: 20px;

				h2 {
					font-size: 14px;
					color: $text-blue;
				}

				div {
					font-size: 12px;
					color: $text-blue;
				}

				@include media-breakpoint-down(md) {
					width: 85%;
				}
			}
		}

		.fiftyPercentPaymentOption-content {
			position: relative;
			padding: 2em;
			overflow: hidden;
			background: $fiftyPercentPaymentOption-content-background;

			@include media-breakpoint-down(md) {
				padding: 3em 0 0 0;
				margin-top: 2em;
			}

			&:before {
				position: absolute;
				bottom: -40%;
				left: -30%;
				z-index: 0;
				width: 80%;
				height: 100%;
				content: '';
				background: $fiftyPercentPaymentOption-content-gradient-background;
				opacity: 0.3;

				@include media-breakpoint-down(md) {
					bottom: -50vw;
					left: -50vw;
					width: 100%;
					height: 100vw;
				}
			}
		}

		.fiftyPercentPaymentOption {
			position: relative;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			font-size: 1.2em;
			color: $fiftyPercentPaymentOption-color;

			@include media-breakpoint-down(lg) {
				font-size: 1em;
			}

			@include media-breakpoint-down(md) {
				font-size: 1em;
			}

			.fiftyPercentPaymentOption-left, .fiftyPercentPaymentOption-right {
				padding: 0;
			}

			.fiftyPercentPaymentOption-left {
				@include media-breakpoint-down(md) {
					margin-bottom: 1em;
				}
			}

			.fiftyPercentPaymentOption-header {
				font-size: 2.1em;
				line-height: 1.2em;
				color: $fiftyPercentPaymentOption-header-color;
				word-break: break-word;

				@include sf_pro_textsemibold;

				B {
					font-weight: normal;
					color: $fiftyPercentPaymentOption-header-highLighted-color;

					@include sf_pro_textmedium;
				}

				@include media-breakpoint-down(md) {
					font-size: 1.9em;
				}

				.color-secondary-green {
					color: $fiftyPercentPaymentOption-header-highLighted-color;
				}
			}

			.fiftyPercentPaymentOption-lead {
				margin-right: 20px;
				margin-bottom: 20px;
				line-height: 1.2em;

				BR {
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}

				@include media-breakpoint-down(md) {
					margin-right: 0;
				}
			}

			.fiftyPercentPaymentOption-btn {
				position: relative;
				padding: 0.8em 2.5em 0.8em 0.8em;
				font-size: 1em;
				line-height: 1.5em;
				color: $fiftyPercentPaymentOption-button-color;
				text-align: left;
				text-decoration: none;
				background: $fiftyPercentPaymentOption-button-background;
				border: 0;

				&:hover {
					color: $fiftyPercentPaymentOption-button-color;
					background: $fiftyPercentPaymentOption-button-hover-background;
				}

				@include sf_pro_textmedium;

				I {
					position: absolute;
					top: 2.2em;
					right: 1em;
					display: block;
					width: fit-content;
					height: fit-content;
					font-size: 10px;
					font-weight: normal;

					@include media-breakpoint-down(lg) {
						top: 1.8em;
					}
				}

			}

			.fiftyPercentPaymentOption-steps {
				margin: 0;
				list-style: none;
				counter-reset: fifty-percent-steps-counter;

				@include media-breakpoint-down(md) {
					padding: 0;
				}

				@include media-breakpoint-up(lg) {
					margin-left: 30px;
				}

				li {
					position: relative;
					display: block;
					min-height: fit-content;
					padding: 15px 0;
					font-size: 16px;
					line-height: 100%;
					counter-increment: fifty-percent-steps-counter;

					@include media-breakpoint-down(md) {
						padding: 40px 0 0 0;
					}

					@include media-breakpoint-down(md) {
						margin: 30px 0 25px 0;
					}

					&:before {
						position: absolute;
						top: 0;
						bottom: 0;
						left: -45px;
						width: 30px;
						height: 32px;
						margin: auto;
						font-size: 1.8em;
						line-height: 1em;
						text-align: center;
						content: counter(fifty-percent-steps-counter);

						@include sf_pro_textmedium;

						@include media-breakpoint-down(md) {
							bottom: initial;
							left: 0;
							text-align: left;
						}
					}

					SPAN {
						display: inline-block;
						line-height: 1.2em;
						vertical-align: middle;
					}

					B {
						font-family: sf_pro_textsemibold;
						font-weight: normal;

						@include sf_pro_textmedium;
					}
				}
			}
		}
	}
}