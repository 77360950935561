@import "general/import-general-only";
@import "variables/info-page";
@import "./layout/common/contact-page-form-block";

.left_sidebar_right_content.container {
	padding-bottom: 15px;
}

.smaller-font {
	font-size: smaller;
}

MAIN {
	position: relative;
	z-index: 1;
	background: $infopage-background;

	.left_content_right_sidebar {
		@include media-breakpoint-up(md) {
			padding: 0;
		}

		#breadcrumb {
			padding: 6px 15px;
		}
	}

	.container {
		@include media-breakpoint-up(md) {
			padding-right: 0;
			padding-left: 0;
		}

		.content {
			@include media-breakpoint-down(sm) {
				flex-direction: column;

				.content-container {
					order: 1;
					margin-bottom: 25px;
				}

				.side-container {
					order: 2;
				}
			}

			.side-container {
				@import "layout/common/side-bar";
			}

			.content-container {
				padding: 0 15px;

				@import "layout/general-infopage";

				.content {
					.faq {
						h2 {
							margin-top: 10px;
							font-size: 18px;

							@include sf_pro_textsemibold;
						}

						.questionBlock {
							border-bottom: 1px solid $infopage-questionBlock-border-color;

							h3.faqTitle {
								margin: 0.4em 0 0.5em 0;
								font-size: 12px;
								color: $infopage-faqTitle-color;
								cursor: pointer;

								i {
									font-size: 18px;
									line-height: 12px;
									vertical-align: top;
									transition: transform 0.3s ease;
									transform: rotate(0);

									&.rotate {
										transition: transform 0.3s ease;
										transform: rotate(90deg);
									}
								}

								&:hover {
									color: $infopage-questionBlock-hover-color;
								}
							}

							div.faqDescription {
								padding: 0 5px 5px 12px;
								font-size: 12px;
								line-height: 16px;
								color: $infopage-faqDescription;
							}
						}
					}
				}
			}
		}
		
		table {
			tr td {
				width: 50%;
				padding: 5px;
				border: 1px solid black;

				&:nth-child(2n) {
					border-left: unset;
				}
			}
			
			margin-bottom: 1rem;
		}
	}
}

@import "layout/common/modals/how-we-work-video-modal";