@import "../../variables/layout/common/side-bar";

& { // stylelint-disable-line scss/selector-no-redundant-nesting-selector
	@include media-breakpoint-down(md) {
		padding: 0 15px;
	}
}

.sidebar_menu {
	padding: 15px 0;
	margin: 0 0 20px 0;
	background: $sidebar-background-color;
	border-radius: 3px;
	box-shadow: 0 0 3px $sidebar-boxshadow-color;

	a {
		padding: 10px 15px;
		font-size: 14px;
		color: $sidebar-link-color;
		text-decoration: none;
		border-bottom: 1px solid $sidebar-link-border-bottom-color;

		&.active, &:hover {
			color: $sidebar-link-hover-color;
			background: $sidebar-link-hover-background;
		}
	}
}

.box {
	@include media-breakpoint-down(md) {
		text-align: center;
	}

	.whychoose {
		width: 100%;
		padding: 0;
		margin: 0;
		color: $sidebar-box-color;
		background: linear-gradient($sidebar-box-background-1, $sidebar-box-background-2);

		.row {
			margin: 0;
		}

		.tableContainer {
			padding: 0;
			margin: 0;

			.tableRow {
				margin: 10px;

				.tableCell {
					&.title {
						padding: 5px;

						.box {
							padding-left: 0;
							font-size: 16px;
							line-height: 18px;

							@include sf_pro_textsemibold;
						}
					}

					.box {
						position: relative;
						padding-left: 20px;
						font-size: 12px;
						line-height: 19.2px;
						text-align: left;
						background: transparent;

						i {
							position: absolute;
							top: 5px;
							left: 3px;
						}

						a {
							color: $sidebar-box-color;
							text-decoration: underline;

							@include sf_pro_textsemibold;
						}
					}

					&.more_link {
						display: none;
					}
				}
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}

	.description {
		padding: 10px;
		font-size: 14px;
		line-height: 15px;
		color: $sidebar-box-description-color;
		background: $sidebar-box-description-background;

		.quote {
			font-size: 15.4px;
			font-style: italic;
			line-height: 21px;
		}

		.name {
			padding-top: 5px;
			font-size: 12.6px;
			line-height: 18px;
		}
	}

	.more_link {
		text-align: right;
		background: transparent !important;

		&.row {
			padding: 0;
			margin: 0;
		}

		[class*="col-"] {
			padding: 0;
			margin: 0;
		}

		a {
			font-size: 12px;
			color: $sidebar-box-morelink-color;
		}
	}

}

hr {
	height: 0;
	margin: 1.25em 0 1.1875em;
	clear: both;
	border: 1px solid $sidebar-box-hr-color;
}